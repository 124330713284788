import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';

import { translate } from '@helpers/i18n';

import { DeliveryDTOWithStatus, ReplanningStatus } from '@shared/types';
import styles from './DeliveriesList.module.scss';

interface DeliveriesListProps {
  whCode: string;
  date: string;
  deliveries: DeliveryDTOWithStatus[];
  onSubmit: () => void;
  isReplanning: boolean;
}

const DeliveriesList = ({
  onSubmit,
  whCode,
  date,
  deliveries,
  isReplanning,
}: DeliveriesListProps): JSX.Element => {
  function getStatusClass(status: ReplanningStatus) {
    if (status === 'new') {
      return styles['route-added'];
    }

    if (status === 'deleted') {
      return styles['route-deleted'];
    }

    return '';
  }

  return (
    <Row className={styles.DeliveriesList}>
      <h3 className={`m-0 p-3 ps-4 ${styles['border-bottom']}`}>
        {isReplanning
          ? translate('DELIVERY_LIST.CONFIRM_REPLANNING')
          : translate('DELIVERY_LIST.CONFIRM_PLANNING')}
      </h3>
      <div className={`p-4 ${styles['border-bottom']}`}>
        <span className="color-gray">{translate('DELIVERY_LIST.WHAREHOUSE')}: </span>
        <span className="color-blue me-4 font-bold">
          {translate('DELIVERY_LIST.WHAREHOUSE')} V{whCode}
        </span>
        <span className="color-gray">{translate('DELIVERY_LIST.DATE')}: </span>
        <span className="color-blue font-bold">{date}</span>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th>{translate('DELIVERY_LIST.CLIENT_ENTERPRISE')}</th>
            <th>{translate('DELIVERY_LIST.ROUTE_ID')}</th>
            <th>{translate('DELIVERY_LIST.DELIVERY_ADDRESS')}</th>
            <th>{translate('DELIVERY_LIST.DELIVERY_HOURS')}</th>
            <th>{translate('DELIVERY_LIST.LOCATION')}</th>
          </tr>
        </thead>
        <tbody>
          {deliveries.map((d: DeliveryDTOWithStatus) => (
            <tr key={d.id} className={`${getStatusClass(d.status)}`}>
              <td>{d.placeName}</td>
              <td>{d.ruteNumber}</td>
              <td>{d.address1}</td>
              <td>{d.deliveryHours}</td>
              <td>
                {d.latitude}, {d.longitude}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="p-3 pt-0 d-flex justify-content-end">
        <Button onClick={onSubmit}>
          {isReplanning ? translate('DELIVERY_LIST.TO_REPLAN') : translate('DELIVERY_LIST.TO_PLAN')}
        </Button>
      </div>
    </Row>
  );
};

export default DeliveriesList;
