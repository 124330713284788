import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

export class HTTPService {
  private api: AxiosInstance;
  private token: string | null = null;

  constructor() {
    this.api = axios.create();

    // this middleware is been called right before the http request is made.
    this.api.interceptors.request.use((config: AxiosRequestConfig) => {
      const newConfig = { ...config };

      if (this.token && newConfig.headers) {
        newConfig.headers.Authorization = `Token ${this.token}`;
      }

      return {
        ...newConfig,
      };
    });

    // middleware called right before the response is served
    this.api.interceptors.response.use(
      (response: AxiosResponse) => {
        return response;
      },
      (error) => {
        return Promise.reject(error.response.data);
      },
    );
  }

  public setToken(token: string | null): void {
    this.token = token;
  }

  public getUri(config?: AxiosRequestConfig): string {
    return this.api.getUri(config);
  }

  public request<T, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> {
    return this.api.request(config);
  }

  public get<T, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.api.get(url, config);
  }

  public delete<T, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.api.delete(url, config);
  }

  public head<T, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.api.head(url, config);
  }

  public post<T, R = AxiosResponse<T>>(
    url: string,
    data?: object,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this.api.post(url, data, config);
  }

  public put<T, R = AxiosResponse<T>>(
    url: string,
    data?: object,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this.api.put(url, data, config);
  }

  public patch<T, R = AxiosResponse<T>>(
    url: string,
    data?: object,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this.api.patch(url, data, config);
  }
}

const httpService = new HTTPService();

export default httpService;
