import configuration from '@config/index';
import dayjs from '@helpers/dayjs';
import { DeliveryDTO, SmartMonkeyPlanDTO } from '@shared/interfaces';
import { DeliveryDTOWithStatus } from '@shared/types';
import httpService from './HTTP.service';

class PlanService {
  private readonly apiUrl = `${configuration.apiUrl}/plan`;
  private readonly http = httpService;

  private formatDate = (date: string) => dayjs(date).format('YYYYMMDD');

  async createPlan(
    date: string,
    whCode: string,
    deliveries: Array<DeliveryDTO>,
  ): Promise<SmartMonkeyPlanDTO[]> {
    const {
      data: { data },
    } = await this.http.post<{ data: SmartMonkeyPlanDTO[] }>(this.apiUrl, {
      date: this.formatDate(date),
      whCode,
      deliveries,
    });

    return data;
  }

  async getPlanByWhCodeAndDate(date: string, whCode: string): Promise<SmartMonkeyPlanDTO> {
    const params = new URLSearchParams([]);
    params.append('whCode', whCode);
    params.append('date', this.formatDate(date));

    const {
      data: { plan },
    } = await this.http.get<{ plan: SmartMonkeyPlanDTO }>(this.apiUrl, {
      params,
    });

    return plan;
  }

  async replanning(
    planId: string,
    date: string,
    whCode: string,
  ): Promise<Array<DeliveryDTOWithStatus>> {
    const {
      data: { deliveries },
    } = await this.http.post<{ deliveries: Array<DeliveryDTOWithStatus> }>(
      `${this.apiUrl}/${planId}/replanning`,
      {
        date: this.formatDate(date),
        whCode,
      },
    );

    return deliveries;
  }

  async updatePlan(
    planId: string,
    date: string,
    whCode: string,
    deliveries: Array<DeliveryDTOWithStatus>,
  ): Promise<SmartMonkeyPlanDTO[]> {
    const newDeliveries = deliveries.filter((d) => d.status === 'new');
    const deletedDeliveries = deliveries.filter((d) => d.status === 'deleted');
    const {
      data: { data },
    } = await this.http.put<{ data: SmartMonkeyPlanDTO[] }>(`${this.apiUrl}/${planId}`, {
      date: this.formatDate(date),
      whCode,
      newDeliveries,
      deletedDeliveries,
    });

    return data;
  }
}

const planServiceInstance = new PlanService();

export default planServiceInstance;
