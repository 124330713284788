import configuration from '@config/index';
import httpService from './HTTP.service';

class AuthService {
  private readonly apiUrl = `${configuration.apiUrl}/auth/login`;
  private readonly http = httpService;
  private readonly KEY_TOKEN = 'LACTEAS_BACKOFFICE_TOKEN';
  private token: string | null = null;

  constructor() {
    if (typeof window !== 'undefined') {
      this.init();
    }
  }

  private init() {
    this.setToken(this.getTokenFromLocalStorage());
  }

  private setToken(token: string | null) {
    if (token === null) {
      localStorage.removeItem(this.KEY_TOKEN);
    } else {
      localStorage.setItem(this.KEY_TOKEN, token);
    }

    this.http.setToken(token);
    this.token = token;
  }

  private getTokenFromLocalStorage(): string | null {
    return localStorage.getItem(this.KEY_TOKEN);
  }

  isAuthenticated(): boolean {
    return this.token !== null && this.token !== '';
  }

  async login(username: string, password: string): Promise<void> {
    const {
      data: { token },
    } = await this.http.post<{ token: string }>(this.apiUrl, { username, password });

    this.setToken(token);
  }

  logout(): void {
    this.setToken(null);
  }
}

const authServiceInstance = new AuthService();

export default authServiceInstance;
