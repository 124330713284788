import I18n from 'i18n-js';

import esTranslations from '@assets/i18n/es';

export const LANGUAGES = ['es'];

export const translate = (text: string): string => I18n.translate(text);

export const changeLanguage = (language: string): boolean => {
  if (!LANGUAGES.includes(language)) {
    return false;
  }

  I18n.locale = language;

  return true;
};

const setupI18n = () => {
  I18n.translations = {
    es: esTranslations,
  };
  // When a value is missing from a language it'll fallback to another language with the key present.
  I18n.fallbacks = true;
  I18n.defaultLocale = 'es';

  // Set the locale once at the beginning of your app.
  changeLanguage(
    typeof window !== 'undefined' ? navigator.language || 'es' : 'es',
  );
};

setupI18n();

export default I18n;
