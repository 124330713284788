import type { ReactElement, ReactChild } from 'react';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import { ToastContainer } from 'react-toastify';
import DefaultLayout from '@ui/layouts/DefaultLayout';
import App from '../App';
import '../styles/globals.scss';
import '@fortawesome/fontawesome-svg-core/styles.css';
import 'react-toastify/dist/ReactToastify.css';

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactChild;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

export default function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout
    ? Component.getLayout
    : (page: ReactElement) => <DefaultLayout>{page}</DefaultLayout>;

  return (
    <>
      <App>{getLayout(<Component {...pageProps} />)}</App>
      <ToastContainer
        position="top-right"
        autoClose={8000}
        hideProgressBar={false}
        newestOnTop={false}
        draggable={false}
        closeOnClick
        pauseOnHover
        theme="colored"
        // pauseOnVisibilityChange
      />
    </>
  );
}
