export default {
  INDEX: {
    HELLO: 'Hola',
  },
  LOGIN: {
    LOGIN: 'Iniciar sesión',
    USER: 'Usuario',
    PASSWORD: 'Contraseña',
    RECOVERY_PASSWORD: '¿Has olvidado tu contraseña?',
    CONTINUE: 'Continuar',
  },
  PLANNING_PAGE: {
    NO_DELIVERIES: 'No existen entregas para este almacén y fecha',
    ERROR_REQUIRED_PARAMETERS:
      'Se deben proporcionar todos los parámetros antes de planificicar',
  },
  DELIVERY_FORM: {
    CHOOSE_WAREHOUSE: 'Escoger almacén',
    CHOOSE_DATE: 'Escoge fecha',
    START_PLANNING: 'Iniciar planificación',
    REPLANNING: 'Replanificar',
  },
  DELIVERY_LIST: {
    CONFIRM_PLANNING: 'Confirmar planificación',
    CONFIRM_REPLANNING: 'Confirmar replanificación',
    WHAREHOUSE: 'ALMACÉN',
    DATE: 'FECHA',
    CLIENT_ENTERPRISE: 'Cliente',
    ROUTE_ID: 'Ruta',
    DELIVERY_ADDRESS: 'Dirección de entrega',
    TO_PLAN: 'Planificar',
    TO_REPLAN: 'Replanificar',
    LOCATION: 'Latitud, Longitud',
    DELIVERY_HOURS: 'Ventana horaria',
  },
  SUCCESS_PLAN: {
    INFORMATION_SENT: 'Información enviada',
    INFORMATION_SENT_DESCRIPTION:
      'Se ha enviado la siguiente planificación correctamente.',
    REPLANNING_SENT: 'Replanificación enviada',
    REPLANNING_SENT_DESCRIPTION:
      'Se ha enviado la siguiente replanificación correctamente.',
    WHAREHOUSE: 'ALMACÉN',
    DATE: 'FECHA',
    TO_CHECK_ROUTE: 'Puedes consultar la ruta en tu cuenta de',
    SMARTMONKEY_PLANNER: 'SmartMonkey Planner',
    TO_PLAN_ROUTE: 'Planificar otra ruta',
  },
  ERROR: {
    USER_PASSWORD_INVALID: 'Usuario o contraseña incorrecto',
    UNEXPECTED_ERROR_OCURRED:
      'Ha ocurrido un error inesperado. Por favor, póngase en contacto con un administrador',
  },
  LOADER: {
    SENDING: 'Enviando',
  },
};
