import { useRouter } from 'next/router';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { Logo } from '@ui/atoms';
import { authServiceInstance } from '@front/services';

import styles from './Header.module.scss';

function Header(): JSX.Element {
  const route = useRouter();

  const logout = async () => {
    authServiceInstance.logout();
    route.push('/login');
  };

  return (
    <header className={styles.Header}>
      <nav className={styles.Nav}>
        <div className="d-flex align-items-center">
          <Logo width={Math.floor(333 / 3.5)} height={Math.floor(199 / 3.5)} />
          <h4 className="ms-5 color-blue">Planificación</h4>
        </div>
        <Button onClick={logout} className={styles['btn-logout']}>
          <span className="me-1 color-gray">Salir</span>
          <FontAwesomeIcon icon={faPowerOff} className="color-blue" />
        </Button>
      </nav>
    </header>
  );
}

export default Header;
