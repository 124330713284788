import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faInfo,
  faExclamationCircle,
  faExclamationTriangle,
  faBug,
} from '@fortawesome/free-solid-svg-icons';

export const displayIcon = (type: string): JSX.Element => {
  switch (type) {
    case 'success':
      return <FontAwesomeIcon icon={faCheck} />;
    case 'info':
      return <FontAwesomeIcon icon={faInfo} />;
    case 'error':
      return <FontAwesomeIcon icon={faExclamationCircle} />;
    case 'warning':
      return <FontAwesomeIcon icon={faExclamationTriangle} />;
    default:
      return <FontAwesomeIcon icon={faBug} />;
  }
};

interface ToastMesageProps {
  type: 'success' | 'info' | 'error' | 'warning';
  message: string;
}

const ToastMessage = ({ type, message }: ToastMesageProps) =>
  toast[type](
    <div style={{ display: 'flex' }}>
      <div style={{ flexGrow: 1, fontSize: 15, padding: '8px 12px' }}>{message}</div>
    </div>,
  );

ToastMessage.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

ToastMessage.dismiss = toast.dismiss;

export default ToastMessage;
