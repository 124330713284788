import environment, { Environment } from './environment';

const getEnvVars = (env = process.env.NEXT_PUBLIC_APP_ENV): Environment => {
  // What is __DEV__ ?
  // This variable is set to true when react-native is running in Dev mode.
  // __DEV__ is true when run locally, but false when published.
  if (env === 'development' || env === 'dev') {
    return environment.dev;
  }
  if (env === 'staging') {
    return environment.staging;
  }
  if (env === 'prod') {
    return environment.prod;
  }

  return environment.prod;
};

const configuration: Environment = getEnvVars();

export default configuration;
