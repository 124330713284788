// import * as dotenv from 'dotenv';

import { LoggerLevels } from '@api/types/LoggerLevers.type';

if (typeof window === 'undefined') {
  // Set dotenv config before reading provess.env attributes, only on backend side
  // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
  require('dotenv').config({ path: '.env.local' });
}

export interface Environment {
  development: boolean;
  production: boolean;
  staging: boolean;
  useMocks: boolean;
  apiUrl: string;
  JWT_SECRET: string;
  smartmonkey: {
    apiUrl: string;
    privateKey: string;
    publicKey: string;
  };
  as400: {
    apiUrl: string;
    apiKey: string;
  };
  logger: {
    level: LoggerLevels;
  };
}

interface Environments {
  dev: Environment;
  prod: Environment;
  staging: Environment;
}

const environment: Environments = {
  dev: {
    development: true,
    staging: false,
    production: false,
    useMocks: false,
    apiUrl: '//localhost:3000/api',
    JWT_SECRET: process.env.JWT_SECRET || '',
    logger: { level: 'debug' },
    smartmonkey: {
      apiUrl: process.env.SMARTMONKEY_API_URL || '',
      privateKey: process.env.SMARTMONKEY_PRIVATE_KEY || '',
      publicKey: process.env.SMARTMONKEY_PUBLIC_KEY || '',
    },
    as400: {
      apiUrl: process.env.AS400_API_URL || '',
      apiKey: process.env.AS400_API_KEY || '',
    },
  },
  staging: {
    development: false,
    staging: true,
    production: false,
    useMocks: false,
    apiUrl: '//lacteas.proj.nakimasolutions.com/api',
    JWT_SECRET: process.env.JWT_SECRET || '',
    logger: { level: 'debug' },
    smartmonkey: {
      apiUrl: process.env.SMARTMONKEY_API_URL || '',
      privateKey: process.env.SMARTMONKEY_PRIVATE_KEY || '',
      publicKey: process.env.SMARTMONKEY_PUBLIC_KEY || '',
    },
    as400: {
      apiUrl: process.env.AS400_API_URL || '',
      apiKey: process.env.AS400_API_KEY || '',
    },
  },
  prod: {
    development: false,
    staging: false,
    production: true,
    useMocks: false,
    apiUrl: '//planner.lacteasdeljarama.com/api/',
    JWT_SECRET: process.env.JWT_SECRET || '',
    logger: { level: 'info' },
    smartmonkey: {
      apiUrl: process.env.SMARTMONKEY_API_URL || '',
      privateKey: process.env.SMARTMONKEY_PRIVATE_KEY || '',
      publicKey: process.env.SMARTMONKEY_PUBLIC_KEY || '',
    },
    as400: {
      apiUrl: process.env.AS400_API_URL || '',
      apiKey: process.env.AS400_API_KEY || '',
    },
  },
};

export default environment;
