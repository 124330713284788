import configuration from '@config/index';
import { DeliveryDTO, WharehouseDTO } from '@shared/interfaces';
import dayjs from '@helpers/dayjs';
import { DeliveryDTOWithStatus } from '@shared/types';
import httpService from './HTTP.service';

class WharehouseService {
  private readonly apiUrl = `${configuration.apiUrl}/wh`; // /api
  private readonly http = httpService;

  async getWharehouses(): Promise<WharehouseDTO[]> {
    const {
      data: { data },
    } = await this.http.get<{ data: WharehouseDTO[] }>(this.apiUrl);

    return data;
  }

  async getDeliveries(whCode: string, date: string): Promise<DeliveryDTOWithStatus[]> {
    const params = new URLSearchParams([]);
    params.append('whCode', whCode);
    params.append('date', dayjs(date).format('YYYYMMDD'));

    const {
      data: { data: deliveries },
    } = await this.http.get<{ data: DeliveryDTO[] }>(`${this.apiUrl}/deliveries`, { params });
    return deliveries.map((d) => ({
      ...d,
      status: 'unchanged',
    }));
  }
}

const wharehouseServiceInstance = new WharehouseService();

export default wharehouseServiceInstance;
