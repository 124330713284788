import Spinner from 'react-bootstrap/Spinner';
import { translate } from '@helpers/i18n';

import styles from './Loader.module.scss';

const Loader = (): JSX.Element => {
  return (
    <div className={styles.ContainerSpinner}>
      <Spinner animation="border" role="status" className={styles.Spinner} />
      <span className="mt-2">{translate('LOADER.SENDING')}</span>
    </div>
  );
};

export default Loader;
