import Button from 'react-bootstrap/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from '@helpers/i18n';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import styles from './SuccessPlan.module.scss';

interface SuccessPlanProps {
  whCode: string;
  date: string;
  isReplanning: boolean;
  onFinish: () => void;
}

const SuccessPlan = ({ whCode, date, isReplanning, onFinish }: SuccessPlanProps): JSX.Element => {
  return (
    <div className={`my-4 ${styles.SuccessPlan}`}>
      <FontAwesomeIcon icon={faCheckCircle} className="icon-check mb-3" />
      <p className="font-bold mb-3">
        {isReplanning
          ? translate('SUCCESS_PLAN.REPLANNING_SENT')
          : translate('SUCCESS_PLAN.INFORMATION_SENT')}
      </p>
      <p>
        {isReplanning
          ? translate('SUCCESS_PLAN.REPLANNING_SENT_DESCRIPTION')
          : translate('SUCCESS_PLAN.INFORMATION_SENT_DESCRIPTION')}
      </p>
      <div className="mt-3">
        <span className="color-gray">{translate('SUCCESS_PLAN.WHAREHOUSE')}: </span>
        <span className="color-blue font-bold">
          {translate('SUCCESS_PLAN.WHAREHOUSE')} V{whCode}
        </span>
      </div>
      <div className="mb-3">
        <span className="color-gray">{translate('SUCCESS_PLAN.DATE')}: </span>
        <span className="color-blue font-bold">{date}</span>
      </div>
      <p>{translate('SUCCESS_PLAN.TO_CHECK_ROUTE')}</p>
      <p>
        <a
          href="https://planner.smartmonkey.io/"
          target="_blank"
          className="anchor"
          rel="noreferrer"
        >
          {translate('SUCCESS_PLAN.SMARTMONKEY_PLANNER')}
        </a>
      </p>
      <Button onClick={onFinish} className="mt-3">
        {translate('SUCCESS_PLAN.TO_PLAN_ROUTE')}
      </Button>
    </div>
  );
};

export default SuccessPlan;
