import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import { translate } from '@helpers/i18n';
import dayjs from '@helpers/dayjs';
import { wharehouseServiceInstance } from '@front/services';
import { WharehouseDTO } from '@shared/interfaces';

import styles from './DeliveryForm.module.scss';

interface DeliveryFormProps {
  isReplanning: boolean;
  onSubmit: () => void;
  setWharehouse: (whCode: string) => void;
  setDate: (whCode: string) => void;
}

const DeliveryForm = ({
  isReplanning,
  onSubmit,
  setWharehouse,
  setDate,
}: DeliveryFormProps): JSX.Element => {
  const [wharehouses, setWharehouses] = useState<WharehouseDTO[]>([]);
  const today = dayjs().format('YYYY-MM-DD');

  useEffect(() => {
    const getWharehouses = async () => {
      try {
        const wh = await wharehouseServiceInstance.getWharehouses();
        setWharehouses(wh);
      } catch (err) {
        // error
      }
    };
    getWharehouses();
    setDate(today);
  }, [setDate, today]);

  return (
    <Col className={styles.DeliveryForm}>
      <Form>
        <Form.Group className="mb-3">
          <Form.Label className="color-blue font-bold">
            {translate('DELIVERY_FORM.CHOOSE_WAREHOUSE')}
          </Form.Label>
          <Form.Select defaultValue="" onChange={({ target }) => setWharehouse(target.value)}>
            <option value="" disabled>
              {translate('DELIVERY_FORM.CHOOSE_WAREHOUSE')}
            </option>
            {wharehouses &&
              wharehouses.map((wh: WharehouseDTO) => (
                <option key={wh.id} value={wh.code}>
                  {wh.label}
                </option>
              ))}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label className="color-blue font-bold">
            {translate('DELIVERY_FORM.CHOOSE_DATE')}
          </Form.Label>
          <Form.Control
            type="date"
            defaultValue={today}
            onChange={({ target }) => {
              setDate(target.value);
            }}
          />
        </Form.Group>
      </Form>
      <div className={`p-0 ${styles['planning-form-btn']}`}>
        <Button onClick={onSubmit}>
          {isReplanning
            ? translate('DELIVERY_FORM.REPLANNING')
            : translate('DELIVERY_FORM.START_PLANNING')}
        </Button>
      </div>
    </Col>
  );
};

export default DeliveryForm;
